import React, { useState, useEffect } from "react";
import { QrReader } from 'react-qr-reader';
import { Link } from "react-router-dom";

const Reader = () => {
  const [data, setData] = useState('Scannez un QR Code...')

  const previewStyle = {
    height: 400,
    width: 500
  }

  useEffect(() => {

      console.log("chargé !")
    return () => {// when unmount component
      console.log("déchargé !")
    }
  }, [])

  return (
    <>
    <div className="qreader-infos">
      <div>{data}</div>
    </div>
    <Link className="qreader-btn" reloadDocument to="/references"><button className="btn btn-primary">Annuler</button></Link>
    <div className="qreader-zone">
      <QrReader
        onResult={(result, error) => {
          if (!!result) {
            let res=result?.text.split("[-]")
            console.log(result?.text)
            let affRes=res.map((line,i)=>
            i<(res.length-1) && <div key={i}>{line}</div>
            )
            let ref=res.slice(-1)
            let link="/references/"+ref
            setData(
            <div>
              {affRes}
              <div><Link reloadDocument to={link}>Référence : {ref}</Link></div>
              </div>
            )
          }

          if (!!error) {
            console.info(error)
          }
        }}
        style={previewStyle}
        constraints={ {facingMode: 'environment'} }
      />
    </div>
    </>
  );
};

export default Reader;
