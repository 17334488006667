import { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios'
import { APICALL_URL } from '../hooks/data'
import { db } from "../hooks/db"
import { CloudArrowUp } from "react-bootstrap-icons"

function Synchro({page, action, actionChange, isOnline}){

      const [listeObjets, setListeObjets] = useState([])
      const [listePhotos, setListePhotos] = useState([])
      const [listePhotosTotal, setListePhotosTotal] = useState()
      const [listeDocsTotal, setListeDocsTotal] = useState()
      const [listeStockages, setListeStockages] = useState()
      const [success, setSuccess] = useState(false)
      const userLocal = JSON.parse(localStorage.getItem("user"))
      const [synchroEnCours, setSynchroEnCours] = useState(false)
      const [idSynchroEncours, setIdSynchroEnCours] = useState()
      const [message, setMessage] = useState("")
      const [nbObjets, setNbObjets] = useState(0)
      const [listeSynchro, setListeSynchro] = useState([])
      const [listeSynchroIds, setListeSynchroIds] = useState([])
      const [listeSynchroPourc, setListeSynchroPourc] = useState([])
      const [progressUpdate, setProgressUpdate] = useState([])
      const [listeTrans, setListeTrans] = useState([])
      const [loaded, setLoaded] = useState(false)
      const [serveurOk, setServeurOk] = useState(false)
      const [serveurErreur, setServeurErreur] = useState("")

      useEffect(() => {
        loadObjets()
        setSuccess(false)
    }, [])

    useEffect(() => {
        //if(loaded) console.log("chargé !")
    }, [loaded])

    useEffect(() => {
        isOnline && testServeur()
    }, [isOnline])

    useEffect(() => {
        setListeSynchroPourc({
            ...listeSynchroPourc,
            [progressUpdate.id]: progressUpdate.value
          });
    }, [progressUpdate])

    useEffect(() => {
        try {
            let nb=0
            listeTrans.forEach((postData) => {
                let idRefLocal=postData.idRefLocal
                axios.post(APICALL_URL,postData,
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,
                        onUploadProgress: e => {
                            handleUploadProgress(e, idRefLocal)
                      },
                    }).then(res => {
                        saveSynchro(idRefLocal,2)
                        deleteLocal(idRefLocal)
                        console.log(nb+"/"+nbObjets)
                        nb++
                        nb===nbObjets && setTimeout(endSync, 2000)
                      })
                      .catch(function (error) {
                          console.log(error)
                          saveSynchro(idRefLocal,3)
                          nb++
                        nb===nbObjets && setTimeout(endSync, 2000)
                        })
            });
          } catch (e) {
            console.log(e);
          }
    },[listeTrans])

    const handleUploadProgress = (progressEvent, id) => {
        const percentCompleted = (progressEvent.loaded * 100) / progressEvent.total;
        setProgressUpdate({ id, value: `${Math.floor(percentCompleted)}` });
    }

    function testServeur(){
        const postData = {
            action:"testServeur",
            accessToken: userLocal.accessToken,
            userId: userLocal.idUser
          }
        axios.post(APICALL_URL,postData,
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          })
          .then(res => {
            if(res.data.retour==="serveur ok"){
                setServeurOk(true)
            }
          })
          .catch(err=>{
            setServeurOk(false)
            setServeurErreur(err.code)
          })
    }

    function endSync(){
        setMessage("Synchronisation terminée")
        setSynchroEnCours(false)
        loadObjets()
    }

    function saveSynchro(index,statut){
        setIdSynchroEnCours(listeObjets[index].id)
        let listeSynchroTemp=listeSynchro
        listeSynchroTemp[index]=statut // synchro en cours
        setListeSynchro(listeSynchroTemp)
        //console.log(listeSynchroTemp)
    }
    
    function synchroniser(){
        setSynchroEnCours(true)
        setMessage("Synchronisation en cours…")
        let nb=0
        let listeTransfers=[]
        listeSynchroIds.forEach(id=>{
            let obj=listeObjets[id]
            let stock=listeStockages[id][0]
            console.log(stock)
            let idRefLocal=obj.id
            let photos=[]
            let docs=[]
            if (listePhotosTotal[idRefLocal]!==undefined) {photos=listePhotosTotal[idRefLocal]}
            if (listeDocsTotal[idRefLocal]!==undefined) {docs=listeDocsTotal[idRefLocal]}
            const postData = {
                action:"saveReference",
                referenceId:"001",
                statut:0,
                idRefLocal:idRefLocal,
                categorie:obj.categorie,
                famille:obj.famille,
                typologie:obj.typologie,
                caracteristiques:obj.caracteristiques,
                phase:obj.phase,
                auteur:obj.auteur,
                editeur:obj.editeur,
                description:obj.description,

                lieu:stock.lieu,
                typeStockage:obj.stock_type,
                caisse:obj.stock_caisse,
                infoStockage:stock.infos,
                zone:stock.zone,
                rangee:stock.rangee,
                niveau:stock.niveau,

                photos:photos,
                docs:docs,

                synchro:"1",

                accessToken: userLocal.accessToken,
                userId: userLocal.idUser
              }
              console.log(postData)
              saveSynchro(idRefLocal,1)
              listeTransfers.push(postData)
        })
        setListeTrans(listeTransfers)
    }

    function deleteLocal(idRefLocal) {
        db.objets
        .where("id").equals(idRefLocal)
        .delete()
        .then(function (deleteCount) {
            console.log( "Deleted " + deleteCount + " objets");
            db.medias
            .where("id_ref").equals(idRefLocal)
            .delete()
            .then(function (deleteCount) {
                console.log( "Deleted " + deleteCount + " images/docs");
            })
        })
    }

    function loadObjets () {
        db.objets.where('id').above(0).sortBy("id").then(
        res=>{
            let tempArray=[]
            res.forEach(obj=>{
                tempArray[obj.id]=obj
            })
            var filtered = tempArray.filter(function (el) {// nettoyage car res retourne aussi des résultats vides
                return el != null;
              })
            setListeObjets(tempArray)
            setNbObjets(filtered.length)
            let listeSynchroTemp=[]
            let listeSynchroIdsTemp=[]
            let listeSynchroPourcTemp=[]
            tempArray.map(obj=>{
                listeSynchroTemp[obj.id]=0
                listeSynchroIdsTemp.push(obj.id)
                listeSynchroPourcTemp[obj.id]=0
            })
            setListeSynchro(listeSynchroTemp)
            setListeSynchroIds(listeSynchroIdsTemp)
            setListeSynchroPourc(listeSynchroPourcTemp)

            // photos de background
            db.medias.where('type').equals('photo').reverse().sortBy("id").then(
                res=>{
                    let tempArray=[]
                    res.forEach(el => {
                        let idRef=el.id_ref
                        tempArray[idRef]=el
                    })
                    setListePhotos(tempArray)

                    db.medias.where('type').equals('photo').sortBy("id").then(
                        res=>{
                            let tempArray=[]
                            res.forEach(el => {
                                let idRef=el.id_ref
                                if (tempArray[idRef]===undefined) tempArray[idRef]=[]
                                tempArray[idRef].push(el)
                            })
                            setListePhotosTotal(tempArray)

                            db.medias.where('type').equals('doc').sortBy("id").then(
                                res=>{
                                    let tempArray=[]
                                    res.forEach(el => {
                                        let idRef=el.id_ref
                                        if (tempArray[idRef]===undefined) tempArray[idRef]=[]
                                        tempArray[idRef].push(el)
                                    })
                                    setListeDocsTotal(tempArray)

                                    db.stockage.where('id').above(0).sortBy("id").then(
                                        res=>{
                                            let tempArray=[]
                                            res.forEach(el => {
                                                let idRef=el.id_ref
                                                if (tempArray[idRef]===undefined) tempArray[idRef]=[]
                                                tempArray[idRef].push(el)
                                            })
                                            setListeStockages(tempArray)
                                            setLoaded(true)
                                        }
                                    )
                                }
                            )
                        }
                    )
                }
            )
        }
    )
    }
    

   

    const navigate = useNavigate();

    
        
    return(
    <div className='container'>
        <div className='row gx-2 gx-md-3'>
            <div className="btns tac mb-4">
                { isOnline &&
                    <>
                    { (!synchroEnCours && listeObjets.length>0) &&
                    <>
                    {loaded &&
                    <>
                        {serveurOk &&
                        <button onClick={synchroniser} className='btn btn-primary'><CloudArrowUp /> &nbsp;SYNCHRONISER MAINTENANT</button>
                        }
                        {!serveurOk &&
                        <p>Erreur : {serveurErreur}</p>
                        }
                    </>
                    }
                    {!loaded &&
                        <div>Chargement des données locales en cours…</div>
                    }
                    </>
                    }
                    { synchroEnCours &&
                    <div>{message}</div>
                    }
                    { listeObjets.length==0 &&
                    <div>Aucun objet à synchroniser !</div>
                    }
                    </>
                }
            </div>
            {listeObjets?.map((ref,index)=>(
                <div onClick={()=>actionChange("viewRefLocal",ref.id)} key={ref.id} className='col-4 col-md-3 mb-2 mb-md-4'>
                    <div className='bloc-ref'>
                        <div className='bloc-ref-cont'>
                        <div className='bloc-ref-visu'
                            style={
                                {backgroundImage:"url("+listePhotos[ref.id]?.file+")"}
                                }></div>
                            <div className="infosRef">
                            <span className="ref-show">{ref.id}</span>
                            </div>
                        </div>
                        {listeSynchro[ref.id]===0 &&
                        <div className="pictoZone synchroNot"></div>
                        }
                        {listeSynchro[ref.id]===1 &&
                        <div className='lds-ring-cont'><span className='info'>{listeSynchroPourc[ref.id]}<span className='fs08'> %</span></span><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
                        }
                        {listeSynchro[ref.id]===2 &&
                        <div className='synchroZonePicto succes'></div>
                        }
                        {listeSynchro[ref.id]===3 &&
                        <div className='synchroZonePicto echec'></div>
                        }
                    </div>
                </div>
            ))}
        </div>
    </div>
    )
}

export default Synchro